// 导入自己需要的组件
import {
    Button,
    Card,
    Message,
    Table,
    TableColumn,
    Container,
    Header,
    Aside,
    Image,
    Main,
    Submenu,
    Menu,
    MenuItem,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Input,
    DatePicker,
    Col,
    Row,
    Pagination,
    TabPane,
    Tabs,
    Notification,
    MessageBox,
    Form,
    FormItem,
    Tag,
    MenuItemGroup,
    Dialog,
    Select,
    Option,
    Breadcrumb,
    BreadcrumbItem,
    Upload,
    Cascader,
    CarouselItem,
    Carousel,
    InputNumber,
    Checkbox,
    CheckboxGroup,
    Radio,
    RadioGroup,
    Tooltip,
    Switch,
    Popover,
    Drawer
} from "element-ui";
const element = {
    install: function(Vue) {
        Vue.use(Button);
        Vue.use(Drawer);
        Vue.use(Popover)
        Vue.use(Radio);
        Vue.use(Switch);
        Vue.use(RadioGroup);
        Vue.use(InputNumber);
        Vue.use(CarouselItem);
        Vue.use(Carousel);
        Vue.use(Upload);
        Vue.use(Select);
        Vue.use(Breadcrumb);
        Vue.use(BreadcrumbItem);
        Vue.use(Option);
        Vue.use(Form);
        Vue.use(Image);
        Vue.use(MenuItemGroup);
        Vue.use(Tag);
        Vue.use(Dialog);
        Vue.use(FormItem);
        Vue.use(Card);
        Vue.use(Container);
        Vue.use(Table);
        Vue.use(TableColumn);
        Vue.use(Header);
        Vue.use(Aside);
        Vue.use(Main);
        Vue.use(Submenu);
        Vue.use(Menu);
        Vue.use(MenuItem);
        Vue.use(Dropdown);
        Vue.use(DropdownItem);
        Vue.use(DropdownMenu);
        Vue.use(Input);
        Vue.use(DatePicker);
        Vue.use(Col);
        Vue.use(Row);
        Vue.use(Pagination);
        Vue.use(TabPane);
        Vue.use(Tabs);
        Vue.use(Cascader);
        Vue.use(Checkbox);
        Vue.use(Tooltip);
        Vue.use(CheckboxGroup);

        Vue.prototype.$message = Message;
        Vue.prototype.$notify = Notification;
        Vue.prototype.$confirm = MessageBox.confirm;
    },
};
export default element;