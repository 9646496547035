import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/info",
    name: "info",
    component: () => import("../views/info.vue"),
  },
  // 登录页面
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  // 重定向，
  { path: "/", redirect: "/home" },
  {
      path: "/home",
      name: "Home",
      component: () => import("../views/home.vue"),
  }
  // {
  //   path: "/home",
  //   name: "Home",
  //   redirect: "/homePage",
  //   component: () => import("../views/homePage/Home.vue"),
  //   children: [
  //     {
  //       path: '/createActivity',
  //       component: () => import('@/views/activityList/createActivity.vue')
  //     },
  //     {
  //       path: '/activityList',
  //       component: () => import('@/views/activityList/list.vue')
  //     },
  //     {
  //       path: '/history',
  //       component: () => import('@/views/activityList/history.vue')
  //     },
  //     {
  //       path: '/seat',
  //       component: () => import('@/views/activityList/rowSeat.vue')
  //     },
  //     {
  //       path: '/orderList',
  //       component: () => import('@/views/order/list.vue')
  //     },
  //     {
  //       path: '/type',
  //       component: () => import('@/views/type/type.vue')
  //     },
  //     {
  //       path: '/invoice',
  //       component: () => import('@/views/invoice/index.vue')
  //     },
  //     {
  //       path: "/homePage",
  //       component: () => import("@/views/home/home.vue"),
  //     },
  //     {
  //       path: "/index",
  //       component: () => import("@/views/home/indexs.vue"),
  //     },
  //     {
  //       path: "/indexs",
  //       component: () => import("@/views/home/index.vue"),
  //     },
  //     {
  //       path: "/ind",
  //       component: () => import("@/views/home/in.vue"),
  //     },
  //   ],
  // },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 100 };
    }
  },
  base: process.env.BASE_URL,
  routes,
});
export default router;
