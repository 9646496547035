import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import element from "./utils/element.js";
import "default-passive-events";
import "element-ui/lib/theme-chalk/index.css";
import i18n from "./i18n";
import { showLoading, hideLoading } from "@/utils/loading.js";
import VueQuillEditor from "vue-quill-editor";
// require styles 导入富文本编辑器对应的样式 EqtCSgYy%MUk
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor /* { default global options } */);
Vue.use(element);
import moment from "moment";
Vue.prototype.$moment = moment;
Vue.prototype.$showLoading = showLoading;
Vue.prototype.$hideLoading = hideLoading;
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0
  // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
  document.body.scrollTop = 0
  document.title = "商用车智慧可视化大屏管理系统";
  // const token = store.state.token || null; // 拿到token
  const token = sessionStorage.getItem("token") || null; // 拿到token
  // 如果你去的不是登录页面 -如果没有token
  if (to.fullPath == "/code") {
    next();
    return;
  } else if (to.fullPath == "/code1") {
    next();
    return;
  } else if (to.fullPath == "/code2") {
    next();
    return;
  } else if (to.fullPath == "/code3") {
    next();
    return;
  } else {
    if (to.fullPath != "/login") {
      if (!token) {
        next({ name: "login" });
        return;
      }
    } else {
      // 有token去首页
      if (token) {
        next({ name: "Home" });
        return;
      }
    }
    next();
  }
});
//
// 时间转换器
Vue.filter("dateFormat", (val) => {
  return moment(val).format("YYYY-MM-DD HH:mm:ss");
});
Vue.filter("dateFormats", (val) => {
  return moment(val).format("YYYY-MM-DD");
});
Vue.filter("dateFormatMonth", (val) => {
  return moment(val).format("YYYY年MM月DD日 ");
});
Vue.filter("dateFormatDay", (val) => {
  return moment(val).format("MM月DD日 ");
});
Vue.filter("dateFormatTime", (val) => {
  return moment(val).format("YYYY年MM月DD日 HH:mm");
});
Vue.filter("dateFormatBranch", (val) => {
  return moment(val).format("MM月DD日 HH:mm ");
});
// 身份证截取
Vue.filter("str", (val) => {
  return (
    val.substring(0, 6) + "******" + val.substring(val.length - 6, val.length)
  );
});
// 地址截取
Vue.filter("assress", (val) => {
  return val.substring(0, val.length - 4) + "*********";
});
// input纯数字

Vue.directive("enterNumber", {
  inserted: function (el) {
    el.addEventListener("keypress", function (e) {
      e = e || window.event;
      let charcode = typeof e.charCode === "number" ? e.charCode : e.keyCode;
      let re = /\d/;
      if (
        !re.test(String.fromCharCode(charcode)) &&
        charcode > 9 &&
        !e.ctrlKey
      ) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
      }
    });
  },
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
