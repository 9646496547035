const mutations = {
  set_token: (state, data) => {
    state.tokenKey = data;
  },
  set_loginUid(state, data) {
    state.loginUid = data
  },
  set_edit(state, data) {
    state.edit = data
  },
  set_order(state, data) {
    state.order = data
  },
  setPath(state, data) {
    state.activePath = data
  },
};
export default mutations;
